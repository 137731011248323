/* At Desktop */

@media all and (max-width:1132px){

  .section{
  
    grid-template-rows: auto 45em 45em auto 45em;
}

.inner-institute-con{
  padding: 6rem 0;
  
} 

    .ins-form-con form{
      width: 24rem;
    }

    .inner-institute-con::before{
       
        width: 47%;
        height: 70%;
      }

      .inner-ins-side-form-con h1{
        font-size: 30px!important;
      }

      .inner-ins-side-text-con h1{

        transform: skewX(10deg);
        padding-right: 3em;
        height: 15em;
        width: 50%;
      
   }
      .img-slider-con{
        max-width: 400px !important;
      }

     
      .Service-inner-con{
        gap: 1em;
        margin: 0 1.4em;
    }

  
    .swiper {
        width: 90%;
      }
 
    
}


/* Tab Size */

@media all and (max-width:899px) {

  .section{
    grid-template-rows: auto auto auto auto auto;
}
 

    .inner-institute-con::before{
        height: 35%;
        clip-path: polygon(50% 0%, 190% 50%, 50% 100%, 0% 50%);
        width: 85%;
        transform: skewX(15deg);
      }

      .inner-ins-side-text-con h1{

        transform: skewX(15deg);
        height: 15em;
        width: 70%;
        padding-right: 0em;
      /*   background-color: white; */
   }

      .inner-ins-side-form-con h1{
        font-size: 30px!important;
        white-space: nowrap;

      }
      .inner-ins-side-form-con{ 
        width: 100%;
      }
      .ins-form-con form{
        width: 90%;
      }


  .MMT-con{
    
    flex-direction: column-reverse;
    gap: 2em;
    padding: 6rem 0;
    justify-content: center;
    align-items: center;
    
  }

  .MMT-inner-con{
    
    width: 100%;
  }

  .MMT-text-inner-con{
   
    width: 100%;
  }

  .img-slider-con{
    max-width: 300px !important;
  }
  .mmt-stepper-con{
    max-width: 300px !important;
  }


  .Service-con{

    flex-direction: row;
    padding: 6rem 0;
   
  }

  .Service-inner-con{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.Whatwevalue-inner-con h1{

  width: 80%;
  color: white;
  font-size: 2em;
  text-align: center;
  padding: 1em 0;
  text-align: center;
  
}
.Innovation-con{

  padding: 6rem 0;
}
.InnovationSliderCard-con{
  flex-direction: column;
}

.inno-card-img-con{
  width: 100%;

}
.inno-card-text-con{
   width: 100%;
}

.swiper {
  width: 80%;
  height: auto;
}

.card-con{

  max-width: 35rem !important;
}
.FooterNav-big-con{
 
  justify-content: center;

  
}
    
}


/* Mobile  Size */

@media all and (max-width:564px) {

 
     .inner-institute-con{
        flex-direction: column;
    } 

    .inner-institute-con::before{
        height: 35%;
        width: 75%;
      }

      .inner-ins-side-text-con h1{
        font-size: 13px;
        height: 15em;
        width: 80%;
   }

      .inner-ins-side-form-con h1{
        font-size: 18px!important;
        white-space: nowrap;

      }
     
      .ins-form-con form{
        width: 80%;
      }

    .ins-form-con h6{
       font-size: 14px !important;
       white-space: nowrap;
    }
     

  .MMT-con h1,.header-text{
    font-size: 2em;
  }


.Whatwevalue-inner-con h1{

  width: 90%;
}
.whatWevalue-stepper-con{
   width: 90%;
}


.swiper {
  width: 19rem;
}

    
}