
  @import url('https://fonts.googleapis.com/css2?family=DynaPuff&family=Roboto+Mono:wght@200&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&family=DynaPuff&family=Roboto+Mono:wght@200&display=swap');
*{
  margin: 0;
  padding: 0;
  font-family: 'DynaPuff cursive', !important ;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


html{
   scroll-behavior: smooth;
}

#root{

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
   
}