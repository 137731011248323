.App{
    width: 100%;
   
}
.ErrorPage{

     width: 100%;
     height: 100vh;
     display: flex;
     justify-content: center;
     align-items: center;
}
.ErrorPage div{
    display: flex;
    justify-content: center;
     align-items: center;
     background-color: gray;
     padding: 30px;
     width: 50%;
     font-weight: 900;
     font-size: 2em;
}


/* Home Page */

.Home-Page-Container{

    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: auto auto;
    position: relative !important;
}


header{
    background-color: rgb(105, 9, 9);
    position: fixed;
    width: 100%;
  
    top: 0;
    z-index: 999;
}

.logo{
    width: 150px;
}
.minlogo{
    width: 100px;
}

.nav-list{
    color: black !important;
}

.nav-app-bar-con{
  position: relative;
  width: 100%;
  height: 100%;
}

/* Section Style codes */
.section{
    grid-column: 1/13;
    margin-top: 3em;
    padding-top: 1em;
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: auto 45em 45em auto 45em ;
}

.institute-con{
    background-image: url(../public/pic7.jpg);
    background-repeat: no-repeat;
    background-size: cover; 
    grid-column: 1/13;
    padding-bottom: 1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.inner-institute-con{
  width: 100%;
  max-width: 1444px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}
 .inner-institute-con::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(20deg,rgba(0, 0, 255, 0.479),rgba(0, 0, 0, 0.945)) ;
  clip-path: polygon(50% 0%, 190% 50%, 50% 100%, 0% 50%);
  transform: skewX(10deg);
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.082);
} 
/* .institute-con::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:linear-gradient(20deg,rgba(0, 0, 0, 0.945));
  z-index: 2;
} */

.inner-ins-side-text-con{
   height: 100%;
   width: 50%;
   display: flex;
   justify-content: flex-end;
  
   color: white;
   z-index: 3;
 

}

.inner-ins-side-text-con h1{

     transform: skewX(10deg);
     padding-right: 3em;
     height: 15em;
     width: 40%;
     font-size: 14px;
   
     
   
}


.inner-ins-side-form-con{
 
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ins-form-con,.ins-form-con{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ins-form-con form{

    background-color: white;
    padding: 20px;
    width: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    border-radius: 7px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.623);
    z-index: 3;
}

.stack-con{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 8px; 
}

.inner-ins-side-form-con h1 {
    color: rgb(92, 12, 12);
    font-size: 40px!important;
    font-weight: bold!important;
    font-family: monospace;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    z-index: 3;
  }
  
.inner-ins-side-form-con h1 {
    padding: 10px;
    background: linear-gradient(to right, rgb(92, 12, 12), hsl(0, 0%, 100%) 10%, hsl(0, 0%, 30%) 20%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
  }
  
  @keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 600px;
    }
    100% {
      background-position: 600px;
    }
  }
   .Hover-elemnt:hover{
      background-color: brown !important;
      color: white !important;
   }
  .explore-text{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .MMT-con{
    background-image: url(../public/imgs/svg-live.svg);
    background-repeat: no-repeat;
    background-size: cover; 
  /*   background-color: rgb(187, 187, 187); */
     grid-column: 1/13;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
   
  }

  .MMT-con h1,.header-text{
    font-size: 4em;
    color: white;
    position: absolute;
    top: 0;
  }
 .wave-con{
  width: 100%;
  position: absolute;
  top: 0;
  
 }

 .MMT-inner-con{
 
   width: 50%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
  
 }

 .slider-con img{
     object-fit: cover !important;
 }
 .slider-con{
    object-fit: contain;
    height: 400px !important;
    width: 100% !important;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.623);
    border-radius: 7px !important;
 }
.MMT-text-inner-con{
 
   width: 50%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}


.Service-con{

  background-image: url(../public/imgs/svg-live.svg);
  background-repeat: no-repeat;
  background-size: cover; 
  grid-column: 1/13;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
 
}

.Service-inner-con{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.Whatwevalue-con{

  background-image: url(../public/imgs/obg.png);
  background-repeat: no-repeat;
  background-size: cover; 
  grid-column: 1/13;
  display: flex;
  flex-direction: row;
  justify-content:  center;
  position: relative;

}

.Whatwevalue-inner-con{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1em;

   
}
.Whatwevalue-inner-con h1{

    width: 50%;
    color: white;
    font-size: 2em;
    text-align: center;
    padding: 1em;
    
}
.whatWevalue-stepper-con .css-1hv8oq8-MuiStepLabel-label{
   color: white !important;
}
.Innovation-con{

  background-image: url(../public/imgs/svg-live-2.png);
  background-repeat: no-repeat;
  background-size: cover; 
  grid-column: 1/13;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  
}

.Innovation-inner-con{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.swiper {
  width: 70em;
  height: 50%;
  border-radius: 7px;  
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: rgba(255, 255, 255, 0);

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.InnovationSliderCard-con{


   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: row;
 
}
.inno-card-img-con{
  width: 50%;
  z-index: 3;
}
.inno-card-img-con img{
  width: 100%;
  height: 100%;
  object-fit: cover;
 
}
.inno-card-text-con{
   width: 50%;
   z-index: 1;
  
}
.inno-card-text-con h1{
   padding: 6px;
}
.inno-card-text-con p{
    text-align: justify;
    padding: 1em;
}

.card-con .css-46bh2p-MuiCardContent-root{
  height: 17em; 
}
.supernova-con p{
    margin-top: 15px;
    margin-bottom: 10px;
}



/* MMT Training Cneter */

.mmt-center-con{
  display: grid;
  grid-template-columns: repeat(12,1fr);
  grid-template-rows: 200px 100px auto 200px;  
}
.mtc-header{
  background-color: white;
  position: fixed;
  height: auto;
  width: 100%;
  z-index: 999;
  box-shadow: 2px 3px 10px rgb(0, 0, 0);
}

.mtc-inner-nav-con{
   justify-content: center;
   align-items: center;
   flex-direction: row !important;
 
}
.mtc-section-1{
  grid-column: 1/13;
  margin-top: 6em;
}
.mtc-aside{
  background-image: url(../public/imgs/svg-live-2.png);
  background-repeat: no-repeat;
  background-size: cover; 
   grid-column: 1/3;
   grid-row: 2/4;
   position: relative;
   border-right: 2px solid rgba(0, 0, 0, 0.158);
  
}
.inner-aside{
   position: sticky;
   background-color: white;
   top: 8rem;
   height: 20rem;
   box-shadow: 2px 3px 10px rgb(0, 0, 0);
   margin: 0px 2px;
}

.mtc-section{
  background-image: url(../public/imgs/svg-live-2.png);
  background-repeat: no-repeat;
  background-size: cover; 
  grid-column: 3/13;
  grid-row: 2/4;
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
  padding: 1.5em;
  justify-content: center;
  align-items: center;
}

.Price-text{
   position: absolute;
   top: 7em;
   left: 0;
   color: white;
   background-color: rgb(92, 12, 12);
   padding: 5px;
   z-index: 3;
   font-weight: 700;
}

.status-text{
  position: absolute;
  top: 7em;
  right: 0;
  color: white;
  background-color: rgb(92, 12, 12);
  padding: 5px;
  z-index: 3;
  font-weight: 700;
}


.mtc-navlist-con{
  width: 100%;
}
.mtc-navlist-con ul{
   display: flex;
   padding: 10px;
}
.mtc-navlist-con ul li {
   list-style: none;
   margin-left: 1em;
   text-transform: capitalize ;
   font-weight: 700;
   color: rgb(92, 12, 12);
}

.mtc-footer{

   background-color: black;
   grid-column: 1/13;
}

footer{
    background-color: rgb(0, 0, 0);
    grid-column: 1/13;
    color: white;
    display: flex;
    flex-direction: column;
   
}

.FooterNav-big-con{
   width: 100%;
   height: 300px;
   display: flex;
   justify-content: start;

   
}
.FooterNav-Con{
   width: 320px;
   max-width: 350px;
   height: 60%;
   display: flex;
   flex-direction: column;
   color: white !important;
   margin-top: 1em;
   margin-left: 5px;

}
.FooterNavCard-con{
  border-left: 1px solid rgba(255, 255, 255, 0.747);
}
.footerATdate{
  color: white;
  width: 100%;
   height: auto;
   text-align: center;
   padding: 6px 0;

}


.Contact-us-con{

  background-image: url(../public/imgs/svg-live-2.png);
  background-repeat: no-repeat;
  background-size: cover; 
  grid-column: 1/13;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  
}

.inner-map-con{
   width: 100%;
   height: 100%;
   padding: 1em;
   display: flex;
   justify-content: center;
   align-items: center;
}